var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,staticClass:"table-row",class:{
    'v-data-table__mobile-table-row': !_vm.$vuetify.breakpoint.mdAndUp,
  },on:{"click":function($event){$event.stopPropagation();return _vm.clickRow(_vm.item)},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_c('div',[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")])]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.item.id))])])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__20-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("logs_changer_name"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm._f("dateTime")(_vm.item.created_at,_vm.$helpers.secondHourDayMonthAndYearDate))+" ")]),(_vm.item.employee_id)?_c('employee-card-details',{attrs:{"details-in-tooltip":"","justify-left":"","clickable":"","push-transition":"","employee-id":_vm.item.employee_id}}):(_vm.item.user)?_c('user-card-details',{attrs:{"details-in-tooltip":"","justify-left":"","clickable":"","push-transition":"","user":_vm.item.user}}):_vm._e()],1)]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("logs_type_title"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('v-chip',{staticClass:"mb-2",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.$trans("logs_type_" + _vm.item.type))+" ")]),_c('div',[_vm._v(" ("+_vm._s(_vm.$trans("id"))+": "+_vm._s(_vm.item.subject_id)+") "+_vm._s(_vm.$trans("logs_subject_type_" + _vm.item.object_type))+" ")])],1)]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__30-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("logs_diff_before_title"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.diff_before)?_c('div',_vm._l((_vm.item.diff_before),function(value,key){return _c('div',{key:key},[(value && !_vm.hiddenFields.includes(key))?[_c('log-item',{attrs:{"value-key":key,"value":value,"object-type":_vm.item.object_type}})]:_vm._e()],2)}),0):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__30-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("logs_diff_after_title"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.diff_after)?_c('div',_vm._l((_vm.item.diff_after),function(value,key){return _c('div',{key:key},[(
              !(_vm.item.type === 'created' && !value) &&
              !_vm.hiddenFields.includes(key)
            )?[_c('log-item',{attrs:{"value-key":key,"value":value,"object-type":_vm.item.object_type}})]:_vm._e()],2)}),0):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }