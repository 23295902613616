<template>
  <div v-if="!isSending" class="list mb-16">
    <logs-filter
      @filtersChanged="filtersChanged"
      @filtersRestored="filtersRestored"
    />
    <v-data-table
      :footer-props="footerProps"
      :headers="headers"
      :items="items"
      :loading="isLoadingTable"
      :loading-text="$trans('loading')"
      :no-data-text="$trans('nothing_found_here')"
      :no-results-text="$trans('nothing_found_here')"
      :options.sync="tableOptions"
      :server-items-length="total"
      class="calendesk-datatable"
      mobile-breakpoint="960"
    >
      <template #[`item`]="{ item }">
        <log-row :item="item" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import sharedList from "@/calendesk/mixins/sharedList";
import { mapActions } from "vuex";
import LogRow from "@/views/dashboard/pages/Tools/Logs/LogRow.vue";
import LogsFilter from "@/views/dashboard/pages/Tools/Logs/LogsFilter.vue";

export default {
  name: "LogsList",
  components: {
    LogsFilter,
    LogRow,
  },
  mixins: [sharedList],
  data() {
    return {
      cacheId: "logs-list",
      requestActionName: "fetchLogsList",
      headers: [
        {
          text: this.$trans("id"),
          align: "start",
          value: "id",
          class: "header-style",
          sortable: true,
        },
        {
          text: this.$trans("logs_changer_name"),
          value: "created_at",
          class: "header-style",
          sortable: true,
        },
        {
          text: this.$trans("logs_type_title"),
          value: "type",
          class: "header-style",
          sortable: false,
        },
        {
          text: this.$trans("logs_diff_before_title"),
          value: "diff_before",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("logs_diff_after_title"),
          value: "diff_after",
          sortable: false,
          class: "header-style",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchLogsList: "auth/getLogs",
    }),
  },
};
</script>
