<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @click.stop="clickRow(item)"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      class="pt-2 text-center"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__20-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("logs_changer_name") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="mb-2">
          {{
            item.created_at | dateTime($helpers.secondHourDayMonthAndYearDate)
          }}
        </div>
        <employee-card-details
          v-if="item.employee_id"
          details-in-tooltip
          justify-left
          clickable
          push-transition
          :employee-id="item.employee_id"
        />
        <user-card-details
          v-else-if="item.user"
          details-in-tooltip
          justify-left
          clickable
          push-transition
          :user="item.user"
        />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("logs_type_title") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-chip class="mb-2" x-small>
          {{ $trans("logs_type_" + item.type) }}
        </v-chip>
        <div>
          ({{ $trans("id") }}: {{ item.subject_id }})
          {{ $trans("logs_subject_type_" + item.object_type) }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__30-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("logs_diff_before_title") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div v-if="item.diff_before">
          <div v-for="(value, key) in item.diff_before" :key="key">
            <template v-if="value && !hiddenFields.includes(key)">
              <log-item
                :value-key="key"
                :value="value"
                :object-type="item.object_type"
              />
            </template>
          </div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__30-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("logs_diff_after_title") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div v-if="item.diff_after">
          <div v-for="(value, key) in item.diff_after" :key="key">
            <template
              v-if="
                !(item.type === 'created' && !value) &&
                !hiddenFields.includes(key)
              "
            >
              <log-item
                :value-key="key"
                :value="value"
                :object-type="item.object_type"
              />
            </template>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
import itemRowActions from "@/calendesk/mixins/itemRowActions";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import EmployeeCardDetails from "@/components/EmployeeCardDetails.vue";
import UserCardDetails from "@/components/UserCardDetails.vue";
import LogItem from "@/views/dashboard/pages/Tools/Logs/LogItem.vue";

export default {
  components: {
    LogItem,
    UserCardDetails,
    EmployeeCardDetails,
  },
  mixins: [itemRowActions, sharedActions],
  data() {
    return {
      hiddenFields: [
        "starts_at",
        "ends_at",
        "last_starts_at",
        "last_ends_at",
        "created_by_user_id",
        "default_booking_status",
        "stripe_product_price_id",
        "zoom_code",
        "event_key",
        "outlook_event_key",
        "allow_dynamic_duration",
        "updated_by_user_id",
        "key",
      ],
    };
  },
};
</script>
