<template>
  <div class="d-flex">
    <div v-if="valueKey === 'custom_fields'">
      <v-container fluid class="px-0 mx-0">
        <event-custom-fields dense :custom-fields="value" />
      </v-container>
    </div>
    <div v-else>
      <span class="font-weight-medium mr-1">
        {{ $trans("logs_diff_" + valueKey) }}:
      </span>
      <span
        v-if="
          (objectType === logSubjectTypes.servicelocation ||
            objectType === logSubjectTypes.location) &&
          valueKey === 'type'
        "
      >
        {{ $trans("service_location_" + value) }}
      </span>
      <span v-else-if="typeof value === 'boolean'">
        {{ $trans(value ? "yes" : "no") }}
      </span>
      <span v-else-if="value === 'active'">
        {{ $trans("active") }}
      </span>
      <span v-else-if="value === 'inactive'">
        {{ $trans("inactive") }}
      </span>
      <span v-else-if="value === 'canceled'">
        {{ $trans("canceled") }}
      </span>
      <span v-else-if="value === 'initiated'">
        {{ $trans("initiated") }}
      </span>
      <span v-else-if="value === 'approved'">
        {{ $trans("status_approved") }}
      </span>
      <span v-else-if="value === 'cancelled'">
        {{ $trans("status_cancelled") }}
      </span>
      <span v-else-if="value === 'waiting'">
        {{ $trans("status_waiting") }}
      </span>
      <span v-else-if="value === 'payment'">
        {{ $trans("status_payment") }}
      </span>
      <span v-else-if="value === 'done'">
        {{ $trans("status_done") }}
      </span>
      <span v-else-if="value === 'cash'">
        {{ $trans("payment_method_cash") }}
      </span>
      <span v-else-if="value === 'stripe'">
        {{ $trans("payment_method_stripe") }}
      </span>
      <span v-else-if="value === 'transfer'">
        {{ $trans("payment_method_transfer") }}
      </span>
      <span v-else-if="value === 'card'">
        {{ $trans("payment_method_card") }}
      </span>
      <span v-else-if="value === 'subscription'">
        {{ $trans("payment_method_subscription") }}
      </span>
      <span v-else-if="priceFields.includes(valueKey)">
        {{ value | money }}
      </span>
      <span v-else-if="durationFields.includes(valueKey)">
        {{ value | duration }}
      </span>
      <span v-else class="text-wrap text-break">{{ value }}</span>
    </div>
  </div>
</template>
<script>
import EventCustomFields from "@/components/Event/EventCustomFields.vue";
import { logSubjectTypes } from "@/calendesk/prototypes/logSubjectTypes";

export default {
  components: { EventCustomFields },
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
    objectType: {
      type: [String],
      default: null,
    },
    valueKey: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      logSubjectTypes: logSubjectTypes,
      priceFields: ["price"],
      durationFields: [
        "duration",
        "booking_time_before",
        "cancel_time_before",
        "change_time_before",
        "slots_every",
        "awaiting_booking_payment_time",
        "max_booking_time",
      ],
    };
  },
};
</script>
