<template>
  <v-autocomplete
    v-model="selectedTypes"
    outlined
    hide-details="auto"
    :items="inputValues"
    :label="$trans('logs_type_title')"
    :disabled="disabled"
    :rules="rules"
    class="cd-fake-outer"
    :attach="'#' + uniqueId"
    :menu-props="{ offsetY: true }"
    small-chips
    deletable-chips
    clearable
    multiple
    dense
    @change="onChange"
  >
    <template #no-data>
      <div class="v-subheader theme--light">
        {{ $trans("nothing_found_here") }}
      </div>
    </template>

    <template #selection="data">
      <template v-if="chips || smallChips">
        <v-chip
          :small="smallChips"
          :disabled="disabled"
          :close="deletableChips"
          @click:close="removeChip(data.item)"
        >
          <span class="text-truncate">{{ data.item.text }}</span>
        </v-chip>
      </template>
      <template v-else>
        <span class="text-truncate">{{ data.item.text }}</span>
      </template>
    </template>

    <template #item="data">
      <div class="d-flex align-center pointer my-2">
        <span class="text-break cd-v-select-line-height">{{
          data.item.text
        }}</span>
      </div>
    </template>
    <template #append-outer>
      <div :id="uniqueId"></div>
    </template>
  </v-autocomplete>
</template>
<script>
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";
import { logTypeInputValues } from "@/calendesk/prototypes/logSubjectTypes";
import sharedField from "@/calendesk/mixins/sharedField";

export default {
  mixins: [sharedFieldActions, sharedField],
  data() {
    return {
      selectedTypes: this.value,
    };
  },
  computed: {
    inputValues() {
      return logTypeInputValues();
    },
  },
  watch: {
    value(val) {
      this.selectedTypes = val;
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.selectedTypes);
    },
  },
};
</script>
