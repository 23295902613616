<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <calendar-range-input
          v-model="selectedFiltersCreatedDateRange"
          only-past-dates
          dense
          :label="$trans('created_date_range')"
          clearable
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <employee-field
          v-model="selectedFiltersEmployees"
          :label="$trans('employee')"
          dense
          :disabled="disabled"
          multiple
          small-chips
          deletable-chips
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="3">
        <clients-field
          v-model="selectedFiltersUsers"
          hide-actions
          small-chips
          deletable-chips
          multiple
          dense
          :disabled="disabled"
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="3">
        <log-subject-type-select
          v-model="selectedFiltersSubjectTypes"
          hide-actions
          small-chips
          deletable-chips
          multiple
          dense
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <log-type-select
          v-model="selectedFiltersTypes"
          hide-actions
          small-chips
          deletable-chips
          multiple
          dense
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="selectedFiltersSubjectId"
          :label="$trans('subject_id')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import EmployeeField from "@/views/dashboard/pages/Calendar/components/forms/Booking/EmployeeField.vue";
import ClientsField from "@/views/dashboard/pages/Calendar/components/forms/Booking/ClientsField.vue";
import LogSubjectTypeSelect from "@/views/dashboard/pages/Tools/Logs/LogSubjectTypeSelect.vue";
import LogTypeSelect from "@/views/dashboard/pages/Tools/Logs/LogTypeSelect.vue";
import CalendarRangeInput from "@/lib/calendesk-js-library/components/CalendarRangeInput.vue";

export default {
  name: "LogsFilter",
  components: {
    CalendarRangeInput,
    LogTypeSelect,
    LogSubjectTypeSelect,
    ClientsField,
    EmployeeField,
  },
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "logs-filters",
      selectedFiltersSubjectId: null,
      selectedFiltersSearch: null,
      selectedFiltersCreatedDateRange: null,
      selectedFiltersEmployees: null,
      selectedFiltersUsers: null,
      selectedFiltersSubjectTypes: null,
      selectedFiltersTypes: null,
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersSubjectId: this.selectedFiltersSubjectId,
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedFiltersCreatedDateRange: this.selectedFiltersCreatedDateRange,
        selectedFiltersEmployees: this.selectedFiltersEmployees,
        selectedFiltersUsers: this.selectedFiltersUsers,
        selectedFiltersSubjectTypes: this.selectedFiltersSubjectTypes,
        selectedFiltersTypes: this.selectedFiltersTypes,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      if (this.selectedFiltersSubjectId) {
        data.subject_ids = this.selectedFiltersSubjectId;
      }

      if (
        this.selectedFiltersCreatedDateRange &&
        this.selectedFiltersCreatedDateRange.length > 0
      ) {
        data.created_from = this.selectedFiltersCreatedDateRange[0];

        if (this.selectedFiltersCreatedDateRange.length === 2) {
          data.created_to = this.selectedFiltersCreatedDateRange[1];
        } else {
          data.created_to = data.created_from;
        }
      }

      if (this.selectedFiltersUsers && this.selectedFiltersUsers.length > 0) {
        data.user_ids = this.selectedFiltersUsers.map((u) => u.id).join(",");
      }

      if (
        this.selectedFiltersSubjectTypes &&
        this.selectedFiltersSubjectTypes.length > 0
      ) {
        data.subject_types = this.selectedFiltersSubjectTypes.join(",");
      }

      if (this.selectedFiltersTypes && this.selectedFiltersTypes.length > 0) {
        data.types = this.selectedFiltersTypes.join(",");
      }

      if (
        this.selectedFiltersEmployees &&
        this.selectedFiltersEmployees.length > 0
      ) {
        data.employee_ids = this.selectedFiltersEmployees
          .map((e) => e.id)
          .join(",");
      }

      return data;
    },
  },
};
</script>
