import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export const logSubjectTypes = {
  user: "user",
  booking: "booking",
  subscription: "subscription",
  employee: "employee",
  unavailablebookingslot: "unavailablebookingslot",
  service: "service",
  servicetype: "servicetype",
  servicelocation: "servicelocation",
  location: "location",
  serviceresource: "serviceresource",
  serviceemployee: "serviceemployee",
  category: "category",
  userpreferences: "userpreferences",
  usergroup: "usergroup",
  employeeworkinghour: "employeeworkinghour",
  group: "group",
  permission: "permission",
  role: "role",
  subscriptionpaymenttransaction: "subscriptionpaymenttransaction",
  simplestoreproducttransaction: "simplestoreproducttransaction",
  bookingpaymenttransaction: "bookingpaymenttransaction",
  usersubscription: "usersubscription",
  apikey: "apikey",
  availabilityschedule: "availabilityschedule",
  tag: "tag",
  employeewatchedgooglecalendar: "employeewatchedgooglecalendar",
  employeewatchedoutlookcalendar: "employeewatchedoutlookcalendar",
  resource: "resource",
  product: "product",
  bookingtag: "bookingtag",
  bookingsimplestoreproduct: "bookingsimplestoreproduct",
  servicesubscription: "servicesubscription",
  settings: "settings",
  simplestoreproduct: "simplestoreproduct",
  address: "address",
  phone: "phone",
  templatesettings: "templatesettings",
  notificationtemplate: "notificationtemplate",
};

export const logTypes = {
  created: "created",
  updated: "updated",
  deleted: "deleted",
};

export function logSubjectTypeInputValues() {
  return Object.keys(logSubjectTypes).map((key) => {
    return {
      text: trans("logs_subject_type_" + logSubjectTypes[key]),
      value: key,
    };
  });
}

export function logTypeInputValues() {
  return Object.keys(logTypes).map((key) => {
    return {
      text: trans("logs_type_" + logTypes[key]),
      value: key,
    };
  });
}
